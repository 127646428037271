import { ThrowStmt } from "@angular/compiler";
import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ScCommonService } from "src/app/services/common/sc-common.service";
import { ScHttpService } from "src/app/services/http/sc-http.service";
import { ListDataServiceService } from "src/app/services/list-data-service.service";
import { LoadingService } from "src/app/services/loader.service";
import { ServerURLS } from "src/environments/environment";

@Component({
  selector: "app-admin-management",
  templateUrl: "./admin-management.component.html",
  styleUrls: ["./admin-management.component.scss"],
})
export class AdminManagementComponent implements OnInit {
  @ViewChild("loadContent", undefined) loadContent;
  sortBy: any;
  isDesc: boolean = false;
  page = 0;
  pageSize = 20;

  noOfPages: any = 0;
  searchkey: any = "";
  usersList: any = [];
  constructor(
    private route: Router,
    private httpService: ScHttpService,
    private toastr: ToastrService,
    private modelService: NgbModal,
    private commonService: ScCommonService,
    private listDataService: ListDataServiceService,
    private _loaderService: LoadingService
  ) {}

  ngOnInit() {
    this.listDataService.setValueForAdminDetailIsEnableValue(false);

    this.sortBy = {
      code: "downOrder",
      type: "downOrder",
      description: "downOrder",
      rate: "downOrder",
      maximum: "downOrder",
      applycom: "downOrder",
      applufs: "downOrder",
      chargetype: "downOrder",
      status: "downOrder",
    };
    let listDataFromServiceFile =
      this.listDataService.getDataOfSettingAdminManagementList();
    console.log(listDataFromServiceFile);
    // if (listDataFromServiceFile.dataList.length > 0) {
    //   this.page = listDataFromServiceFile.pageSize;
    //   this.pageSize = listDataFromServiceFile.pageCount;
    //   this.usersList = listDataFromServiceFile.dataList;
    //   this.searchkey = listDataFromServiceFile.searchKey;
    // } else {
    //   this.getListofUserManagement(this.page, this.pageSize, this.searchkey);
    // }

    this.getListofUserManagement(this.page, this.pageSize, this.searchkey);

  }
  isDataLoading: any = "";
  getListofUserManagement(page, pageSize, searchkey) {
    this.allDataLoaded = false;
    this.isDataLoading = "";
    this._loaderService.stringSubject.next(true);
    let actorType = 'admin';  // You can change this to any other value as needed.
    let url = ServerURLS.getListOfUsermanagementInAdminSetting.action +
              "/" + page + "/" + pageSize +
              "?actorType=" + actorType;
    
    if (searchkey !== "") {
      url = url + "?searchKey=" + this.searchkey;
    }
    let promise = this.httpService.getHttpService(url, "");
    promise
      .then((res: any) => {
        if (page == 0 && res["response"].length == 0) {
          this.isDataLoading = "No Data Found";
        } else {
          this.isDataLoading = "";
          this._loaderService.stringSubject.next(false);
          this.usersList = res["response"];
          let data = {
            pageCount: this.pageSize,
            pageSize: this.page,
            dataList: this.usersList,
          };
          this.listDataService.setDataForSettingAdminManagementList(data);
        }
      })
      .catch((err) => {
        this._loaderService.stringSubject.next(false);

        this.isDataLoading = "Something Went Wrong! Please Try Again Later";
      });
  }

  sort(property) {
    this.isDesc = !this.isDesc; //change the direction

    let direction = this.isDesc ? 1 : -1;

    this.sortBy[property] = !this.isDesc ? "downOrder" : "upOrder";
    this.usersList.sort(function (a, b) {
      let name = a["firstName"] + " " + a["lastName"];
      let name2 = b["firstName"] + " " + b["lastName"];

      if (name.toLowerCase() < name2.toLowerCase()) {
        return -1 * direction;
      } else if (name.toLowerCase() > name2.toLowerCase()) {
        return 1 * direction;
      }
    });
  }
  openAddNewUserPage() {
    this.isGoingToUserDetailPage = true;
    this.listDataService.setValueForAdminDetailIsEnableValue(
      this.isGoingToUserDetailPage
    );
    sessionStorage.removeItem("_useridinadminsettings");
    this.route.navigateByUrl("shipcarte/settings/addAdminUser");
  }
  ngOnDestroy() {
    if (!this.isGoingToUserDetailPage) {
    }
  }
  isGoingToUserDetailPage: boolean = false;
  goToEditUserManagement(data) {
    this.isGoingToUserDetailPage = true;
    this.listDataService.setSelectedIdForSettingAdminManagmentList(data.id);
    this.listDataService.setValueForAdminDetailIsEnableValue(
      this.isGoingToUserDetailPage
    );
    sessionStorage.setItem(
      "_useridinadminsettings",
      JSON.stringify({
        id: data.id,
        userCode: data.userCode,
        status: data.status,
      })
    );
    this.route.navigateByUrl("shipcarte/settings/adminUserDetails");
  }
  timer: any;
  setSearchKey(value) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (value.length >= 3) {
        this.listDataService.setSearchKeyForSettingAdminManagmentList(value);
        this.page = 0;
        this.searchkey = value;
        this.applyFilter();
      } else if (value.length == 0) {
        this.listDataService.setSearchKeyForSettingAdminManagmentList("");
        this.page = 0;
        this.searchkey = "";
        this.applyFilter();
      }
    }, 500);
  }
  applyFilter() {
    this.getListofUserManagement(this.page, this.pageSize, this.searchkey);
  }
  loadPage(page) {
    this.getListofUserManagement(page, this.pageSize, this.searchkey);
  }
  loadDataPerPage(pageSize) {
    this.pageSize = pageSize;
    this.getListofUserManagement(this.page, pageSize, this.searchkey);
  }

  routeToEdit(id) {
    sessionStorage.setItem("_admincustomerId", id);
    this.commonService.customerId = id;
    console.log(this.commonService.customerId, "customerId");
    this.route.navigateByUrl(`shipcarte/customer/list/generalDetail/edit`);
  }
  isLoading: boolean = false;
  @HostListener("window:scroll", ["$event"])
  private scrollChangeCallback: () => void;
  currentPosition: any;
  startPosition: number;
  showButton: boolean = false;
  // ngAfterViewInit() {
  //   this.scrollChangeCallback = () => this.scrollHandlerStandardCharges(event);
  //   window.addEventListener('scroll', this.scrollChangeCallback, true);
  // }
  allDataLoaded: any = false;
  scrollHandlerPackageManager(e) {
    this.startPosition = e.srcElement.scrollTop;
    let scroll = e.srcElement.scrollTop;

    if (scroll > this.currentPosition) {
      this.showButton = false;
      console.log(
        window.innerHeight + window.scrollY,
        document.body.offsetHeight
      );
      const scrollableDiv = document.querySelector(".scrollable-div");
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight &&
        !this.isLoading
      ) {
        console.log("hello");
        console.log(this.allDataLoaded);
        this.isLoading = true;
        if (!this.allDataLoaded) {
          this.fetchListOfDataToAppend();
        } else {
          this.isLoading = false;
        }
      }
    } else {
      this.showButton = true;
    }
    this.currentPosition = scroll;
  }
  fetchListOfDataToAppend() {
    this.page = this.page + 1;
    let actorType = 'admin';  // You can change this to any other value as needed.


    let url =
      ServerURLS.getListOfUsermanagementInAdminSetting.action +
      "/" +
      this.page +
      "/" +
      this.pageSize +
      "?actorType=" + actorType;;
    if (this.searchkey !== "") {
      url = url + "?searchKey=" + this.searchkey;
    }
    let promise = this.httpService.getHttpService(url, "");
    promise
      .then((res: any) => {
        this.isLoading = false;
        // this.usersList = res['response'];
        if (res["response"].length > 0) {
          this.usersList = this.usersList.concat(res["response"]);
          let data = {
            pageCount: this.pageSize,
            pageSize: this.page,
            dataList: this.usersList,
          };
          this.listDataService.setDataForSettingAdminManagementList(data);
        } else {
          this.allDataLoaded = true;
        }
      })
      .catch((err) => {
        this.isLoading = false;
      });
  }
}
